<template>
  <LoginForm
    :is="loginComponent"
    title="Log In"
    buttonName="Log In"
    @submit="submitLogin"
    @submitCode="handleSubmitCode"/>
</template>

<script>
import LoginForm from '@/components/Login/LoginForm'
import SecurityCode from '@/components/Registration/SecurityCode'

export default {
  name: 'Login',
  components: {
    LoginForm,
    SecurityCode
  },
  data: () => ({
    loginPage: 'LoginForm'
  }),
  computed: {
    loginComponent () {
      const currenPage = this.$store.state.auth.securityPage
      return currenPage === 'SecurityCode' ? 'SecurityCode' : 'LoginForm'
    }
  },
  methods: {
    submitLogin (credentials) {
      this.$store.dispatch('login', credentials)
    },
    handleSubmitCode (code) {
      this.$store.dispatch('verify_code', code)
    }
  }
}
</script>

<style lang="scss">
.login-page {
  @media only screen and (min-width: 0) {
    margin-top: 80px;
    justify-content: center;
    .sidebar-login {
      display: none;
    }
  }

  @media only screen and (min-width: 1280px) {
    margin-top: 0;
    .sidebar-login {
      display: block;
    }
  }
}
</style>
