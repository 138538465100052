<template>
  <div class="login-form mt-auto mb-auto">
    <h3>Log in</h3>
    <form class="mt-8"
          @submit.prevent="submitLogin">
      <div class="input-field">
        <label for="name">Email / Phone</label>
        <input
          class="focus:outline-none"
          id="name"
          type="text"
          placeholder="Your email or phone"
          required
          cy="username"
          v-model.trim="name"
        >
      </div>
      <div class="input-field mb-4">
        <label for="password">Password</label>
        <input
          class="focus:outline-none"
          id="password"
          :type="notEyePassword ? 'password' : 'text'"
          placeholder="Password"
          autocomplete="off"
          required
          cy="password"
          v-model.trim="password"
        >
        <i
          :class="[ notEyePassword ? 'icon-not-eye' : 'icon-eye']"
          @click="notEyePassword = !notEyePassword"
        ></i>
      </div>
      <div class="forgot flex mb-8">
        <div class="cursor-pointer" @click="$router.push('/forgot-password')">Forgot password?</div>
        <div class="ml-auto">
          Don’t have an account?
          <span class="cursor-pointer text-white ml-1"
                @click="$router.push('/sign-up')">Sign up</span>
        </div>
      </div>
      <p v-if="errMsg"
         class="alert">
        {{ errMsg }}
      </p>
      <button type="submit"
              title="Log In"
              cy="login-btn"
              class="btn btn-yellow">Log in</button>
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'LoginForm',
  data: () => ({
    name: '',
    password: '',
    notEyePassword: true,
    disabled: false
  }),
  computed: {
    errMsg () {
      return this.$store.state.auth.errMsg
    }
  },
  methods: {
    submitLogin () {
      if (this.errMsg) this.disabled = false
      if (!this.disabled) {
        this.disabled = true
        this.$emit('submit', {
          user: this.name,
          password: this.password
        })
      }
    }
  }
}
</script>

<style lang="scss">
  .login-form {
    .alert {
      margin-bottom: 16px;
    }
  }
</style>
